import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import profileLogo from "../assets/images/flores-and-sons-logo-profile-pic.png";
import nameplateLogo from "../assets/images/flores-and-sons-logo-nameplate.png";
import "../assets/styles/navbar.css";

function NavBar() {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        fixed="top"
        className="navbar"
      >
        <Container fluid className="navbar-cont">
          <Navbar.Brand href="/home" className="navbar-brand">
            <img
              alt="flores & sons profile logo"
              src={profileLogo}
              width="60"
              height="60"
              className="nav-profile-logo"
            />
            <img
              alt="flores & sons nameplate logo"
              src={nameplateLogo}
              height="60px"
              className="nav-nameplate-logo"
            />
          </Navbar.Brand>
          <div className="navbar-divider"></div>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar-toggle-custom"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav
              fill
              justify
              defaultActiveKey="#home"
              className="navbar-nav me-auto"
            >
              <Nav.Item>
                <Link className="navbar-link" to="/home">
                  <Nav.Link href="/home">Home</Nav.Link>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="navbar-link" to="/estimates">
                  <Nav.Link href="/estimates">Estimates</Nav.Link>
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link className="navbar-link" to="/gallery">
                  <Nav.Link href="/gallery">Gallery</Nav.Link>
                </Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
