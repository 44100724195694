const formatDate = (a, b) => {
  let output;

  const newDateObj = a;
  const seconds = newDateObj.getSeconds();
  const minutes = newDateObj.getMinutes();
  const hour = newDateObj.getHours();
  const hours = ((hour + 11) % 12) + 1;
  // const day = newDateObj.getDay();
  const date = newDateObj.getDate();
  const month = newDateObj.getMonth() + 1;
  const year = newDateObj.getFullYear();

  for (var i = 0; i < 2; i++) {
    switch (b) {
      default:
        console.log("Error, please try again.");
        break;
      case "time":
        output = `${hours > 12 ? hours - 12 : hours}:${
          minutes < 10 ? "0" + minutes : minutes
        }:${seconds} ${hour >= 12 ? "PM" : "AM"}`;
        break;
      case "full_date":
        output = `${month < 10 ? "0" + month : month}/${
          date < 10 ? "0" + date : date
        }/${year}`;
        break;
    }
  }

  return output;
};

const buttonDisabled = ({
  firstName,
  lastName,
  phoneNumber,
  email,
  message,
}) => {
  const valueCheck =
    firstName.length > 0 &&
    lastName.length > 0 &&
    phoneNumber.length > 0 &&
    email.length > 0 &&
    message.length > 0;
  return valueCheck;
};

export { formatDate, buttonDisabled };
