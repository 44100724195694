import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import socialLike from "../assets/images/karsten-winegeart-unsplash.jpg";
import instaLogo from "../assets/images/instagram_logo_icon.png";
import fbLogo from "../assets/images/facebook_logo_icon.png";
import twitterLogo from "../assets/images/twitter_logo_icon.png";
import toolRack from "../assets/images/kenny-eliason-unsplash.jpg";
import estimateSchema from "../schemas/estimateSchema";
import * as util from "../util/util";
import emailjs from "@emailjs/browser";
import "../assets/styles/contact.css";

function Contact() {
  const formik = useFormik({
    initialValues: {
      from_name: "a client",
      to_name: "Elyse",
      subject: "Estimate Request",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      message: "",
      time: "",
      full_date: "",
    },
    validationSchema: estimateSchema,
    onSubmit: ({
      to_name,
      subject,
      firstName,
      lastName,
      phoneNumber,
      email,
      message,
      time,
      full_date,
    }) => {
      console.log("before", time);
      console.log("before", full_date);
      time = util.formatDate(new Date(), "time");
      full_date = util.formatDate(new Date(), "full_date");
      console.log("after", time);
      console.log("after", full_date);
      emailjs
        .send(
          "service_ntfn6rh",
          "template_rln2a7s",
          {
            to_name,
            subject,
            firstName,
            lastName,
            phoneNumber,
            email,
            message,
            time,
            full_date,
          },
          "QKrMEx0Pbqp8AsGe4"
        )
        .then(
          (res) => {
            console.log(res.text);
          },
          (err) => {
            console.log(err.text);
          }
        );
      formik.resetForm();
    },
  });

  return (
    <div className="contact-form-outer-div">
      <div>
        <img src={toolRack} alt="tool rack" className="tool-rack" />
      </div>

      <div className="social-media-info">
        <div className="social-info-cont">
          <div className="social-info-cont-inner">
            <h4>Reach Out!</h4>
            <br />
            <p className="social-para">
              To get in contact with us regarding a job, please fill out the
              form below.
            </p>
            <h5>Follow us on social media!</h5>
            <p className="social-para">You can find us on these platforms!</p>
            <div className="social-links">
              <a href="https://www.facebook.com/profile.php?id=100084405336812">
                <img
                  src={fbLogo}
                  alt="facebook logo"
                  className="social-media-logo facebook"
                />
              </a>
              <a href="https://www.instagram.com/floresandsons61/">
                <img
                  src={instaLogo}
                  alt="instagram logo"
                  className="social-media-logo instagram"
                />
              </a>
              <a href="https://www.twitter.com/floresandsons61/">
                <img
                  src={twitterLogo}
                  alt="twitter logo"
                  className="social-media-logo twitter"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="form-image-cont">
          <img className="form-image" src={socialLike} alt="heart on wall" />
        </div>
      </div>

      <Card className="contact-card" bg="dark" border="dark" text="light">
        <Card.Title className="contact-card-title">
          Request an Estimate!
        </Card.Title>
        <Card.Body className="contact-card-body">
          <Card.Text>
            If you are looking for an estimate please fill out the form below
            and one of our representatives will be in touch with you shortly!
          </Card.Text>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            className="contact-form"
          >
            <Row className="form-row" xs={1} lg={2}>
              <Col xs={12}>
                <Form.Group className="form-group" controlId="first-name">
                  <Form.Label className="form-label">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="John"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    isValid={
                      formik.touched.firstName && !formik.errors.firstName
                    }
                  />
                  <Form.Control.Feedback type="valid">
                    {formik.errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="form-group" controlId="last-name">
                  <Form.Label className="form-label">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Doe"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    isValid={formik.touched.lastName && !formik.errors.lastName}
                  />
                  <Form.Control.Feedback type="valid">
                    {formik.errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row" xs={1} lg={2}>
              <Col xs={12}>
                <Form.Group className="form-group" controlId="phone-number">
                  <Form.Label className="form-label">Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="XXX-XXX-XXXX"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    isValid={
                      formik.touched.phoneNumber && !formik.errors.phoneNumber
                    }
                  />
                  <Form.Control.Feedback tooltip type="valid">
                    {formik.errors.phoneNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group className="form-group" controlId="email">
                  <Form.Label className="form-label">Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@email.com"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    isValid={formik.touched.email && !formik.errors.email}
                  />
                  <Form.Control.Feedback type="valid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-row">
              <Form.Group className="form-group" controlId="message">
                <Form.Label className="form-label">Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Leave a message..."
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  isValid={formik.touched.message && !formik.errors.message}
                />
                <Form.Control.Feedback type="valid">
                  {formik.errors.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Button
              className="form-button-submit"
              variant="light"
              type="submit"
              size="lg"
              disabled={util.buttonDisabled(formik.values) ? false : true}
            >
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Contact;
