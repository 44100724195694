import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import highDesert from "../assets/images/gary-yost-unsplash.jpg";
import "../assets/styles/home.css";

function Home() {
  return (
    <>
      <div className="home-page">
        <img src={highDesert} alt="high desert" className="high-desert" />
        <div className="home-splash">
          <div>
            <h2 className="home-title">Serving the High Desert!</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </div>
        </div>

        <Card className="home-page-card">
          <div className="services-cta-div">
            <h3 className="services-cta">Need a job done?</h3>
            <br />
            <p className="cta-cont-text">
              We offer these services and more across the High Desert!
            </p>
          </div>

          <Container fluid="sm">
            <Row xs={1} lg={2} className="services-row">
              <Col className="row-col-first" lg={4}>
                <Card.Header className="home-page-card-header">
                  Ironwork
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    Security Doors
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Gates and Fences
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Motorized Gates
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Chainlink Fencing
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="row-col-second" lg={4}>
                <Card.Header className="home-page-card-header">
                  Masonry
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    Brick Walls
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Walkways
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Retaining Walls
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Light cement work
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="services-row">
              <Col className="row-col-first" lg={4}>
                <Card.Header className="home-page-card-header">
                  Flooring
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    Hardwood
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Vinyl
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Panels
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Tiles
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="row-col-second" lg={4}>
                <Card.Header className="home-page-card-header">
                  Drywall
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    Installation
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Repair
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Painting
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Walls and Ceilings
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="services-row">
              <Col className="row-col-first" lg={4}>
                <Card.Header className="home-page-card-header">
                  Auto Work
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    General Repairs
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Brakes
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Tune-ups
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Alternators and Starters
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="row-col-second" lg={4}>
                <Card.Header className="home-page-card-header">
                  Maintenance
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item className="home-list-group-item">
                    Gerneral Repair
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Outlets
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Ceiling Fans
                  </ListGroup.Item>
                  <ListGroup.Item className="home-list-group-item">
                    Outdoor Lights
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row xs={1} lg={2} className="area-row">
              <Col lg={10}>
                <Card.Header className="area-list-card-header">
                  Victor Valley communities we serve:
                </Card.Header>
              </Col>
              <Col className="area-row-col-first" lg={3}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="area-list-group-item">
                    Hesperia
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Victorville
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Apple Valley
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="area-row-col-second" lg={3}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="area-list-group-item">
                    Adelanto
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Phelan
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Oak Hills
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col className="area-row-col-third" lg={3}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="area-list-group-item">
                    Lucerne Valley
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Pi<span>&#241;</span>on Hills
                  </ListGroup.Item>
                  <ListGroup.Item className="area-list-group-item">
                    Spring Valley Lake
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Container>
        </Card>
      </div>
    </>
  );
}

export default Home;
