import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import NavBar from "./NavBar";
import Gallery from "./Gallery";
import Contact from "./Contact";
import ScrollToTop from "../util/ScrollToTop";
import "../assets/styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeProvider from "react-bootstrap/ThemeProvider";

function App() {
  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
      >
        <div className="app">
          <ScrollToTop>
            <NavBar />
            <Routes>
              <Route path="home" element={<Home />} />
              <Route path="estimates" element={<Contact />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="*" element={<Navigate to="home" replace />} />
            </Routes>
          </ScrollToTop>
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
