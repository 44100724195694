import * as yup from "yup";
import "yup-phone-lite";

const estimateSchema = yup.object().shape({
  firstName: yup
    .string("Please enter your first name.")
    .required("Please enter your name.")
    .min(1, "Pleast enter a name."),
  lastName: yup
    .string("Please enter your last name.")
    .required("Please enter your name.")
    .min(1, "Pleast enter a name."),
  phoneNumber: yup
    .string()
    .phone("US", "Please enter a valid phone number")
    .required("A phone number is required."),
  email: yup.string().email(),
  message: yup.string(),
});

export default estimateSchema;
